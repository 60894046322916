import { fetchUser } from 'pages/user/store/store';
import { memo, ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router';
import BurgerBlock from 'shared/components/burgerBlock';
import Footer from 'shared/components/footer';
import Header from 'shared/components/header';
import Logo from 'shared/components/logo';
import MainWrapper from 'shared/components/mainWrapper';
import Navbar from 'shared/components/navbar';
import ROUTES from 'shared/constants/routes';
import { useAppDispatch } from 'shared/helpers/hooks/useAppDispatch/useAppDispatch';
import { getOzonAuth } from 'store/selectors/user';
import './index.scss';

type TCabinetLayoutRoute = {
  isAuth: boolean;
  children?: ReactNode;
};

const CabinetLayoutRoute = ({ isAuth, children }: TCabinetLayoutRoute) => {
  const dispatch = useAppDispatch();
  const { isOzonAuth } = useSelector(getOzonAuth);
  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);
  if (!isAuth) {
    return <Navigate to={ROUTES.MAIN.ROUTE} replace />;
  }
  const routesValues = Object.values(ROUTES);
  const headerLinks = routesValues.filter(
    ({ POSITION }) => POSITION === 'cabinetHeader',
  );

  return (
    <>
      {!isOzonAuth ? (
        <>
          <Header layout="Cabinet">
            <Logo to={ROUTES.CABINET_TESTS.ROUTE} layout="Cabinet" />
            <BurgerBlock layout="Cabinet">
              <Navbar links={headerLinks} layout="Cabinet" />
            </BurgerBlock>
            <Navbar links={headerLinks} layout="Cabinet" position="Desktop" />
          </Header>
          <MainWrapper>{children ?? <Outlet />}</MainWrapper>
          <Footer layout="Cabinet" />
        </>
      ) : (
        <div className="body-wrapper">
          <div className="f-line-top"></div>
          <Header layout="Cabinet">
            <Logo to={ROUTES.CABINET_TESTS.ROUTE} layout="Cabinet" />
            <BurgerBlock layout="Cabinet">
              <Navbar links={headerLinks} layout="Cabinet" />
            </BurgerBlock>
            <Navbar links={headerLinks} layout="Cabinet" position="Desktop" />
          </Header>
          <MainWrapper>{children ?? <Outlet />}</MainWrapper>
          <div className="empty-footer"></div>
          <div className="f-line-bottom"></div>
        </div>
      )}
    </>
  );
};

export default memo(CabinetLayoutRoute);
