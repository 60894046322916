import { NavLink } from 'react-router-dom';
import ROUTES, { TRoute } from 'shared/constants/routes';
import style from './index.module.scss';
import btnStyle from 'shared/components/button/index.module.scss';
import cl from 'classnames';
import { Icon } from '../icon';
import { FC, PropsWithChildren, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { logoutUser } from 'pages/user/store/store';
import {
  getOzonAuth,
  getUserBalance,
  getUserResponse,
} from 'store/selectors/user';
import Popup from '../popup';
import { Layouts, LayoutValues } from 'shared/layouts/types';
import { Position, PositionValues } from 'app/styles/types';
import { AUTH_TOKEN, ORIGIN_AUTH_TOKEN } from 'shared/constants/commmon';
import { useAppDispatch } from 'shared/helpers/hooks/useAppDispatch/useAppDispatch';
import { userSupportActions } from 'features/ContactSupport/model/slice/userSupportSlice';
import utils from 'shared/helpers/utils';
import { burgerBlockActions } from '../burgerBlock/model/slices/burgerBlockSlice';
import { getPopupIsOpen } from '../burgerBlock/model/selectors/getBurgerBlock';
import { Price } from 'shared/helpers/utils/price';
import { authBlockActions } from 'entities/AuthBlock';
import { useIsIframe } from 'shared/helpers/hooks/useIsIframe/useIsIframe';

const NavbarBtnsId = {
  MoreBtn: 'more-btn',
} as const;

type TNavbar = {
  links: TRoute[];
  layout?: LayoutValues;
  position?: PositionValues;
};

type TNavLink = PropsWithChildren & {
  className?: string;
  withCurrent?: boolean;
};

const Navbar: FC<TNavbar> = ({ links, layout, position }) => {
  const dispatch = useAppDispatch();
  const burgerMenuOpen = useSelector(getPopupIsOpen);
  const userResponse = useSelector(getUserResponse);
  const clientBalance = useSelector(getUserBalance);
  const postCount =
    userResponse &&
    userResponse.response &&
    userResponse.response.data &&
    (!!userResponse.response.data.all_polls_count ||
      userResponse.response.data.all_polls_count === 0)
      ? userResponse.response.data.all_polls_count
      : '';
  const isIndex = layout === Layouts.Index;
  const isCabinet = layout === Layouts.Cabinet;
  const isDesktop = position === Position.Desktop;
  const isMobile = position === Position.Mobile;
  const DEFAULT_LINK_CLASSNAMES = cl(style.link, {
    [style.linkMin]: isCabinet,
  });

  const { isOzonAuth } = useSelector(getOzonAuth);

  const onUserSupportOpen = useCallback(() => {
    dispatch(userSupportActions.setUserSupportOpen());
  }, [dispatch]);

  const [isDownloading, setIsDownloading] = useState(false);

  const onDownloadOffer = async () => {
    try {
      setIsDownloading(true); // Блокируем кнопку

      // Создаем ссылку на файл
      const link = document.createElement('a');
      link.href = '/agreement.pdf';
      link.download = 'agreement.pdf'; // Можно задать любое имя файла

      // Эмулируем клик по ссылке для скачивания
      link.click();

      // Ожидаем небольшую задержку перед разблокировкой кнопки,
      // чтобы избежать быстрого повторного нажатия
      await new Promise(resolve => setTimeout(resolve, 1000));
    } finally {
      setIsDownloading(false); // Разблокируем кнопку
    }
  };
  const [showMenu, setShowMenu] = useState(false);

  const renderLink = ({ ROUTE, NAME, ANKER }: TRoute) => {
    const NavLinkComponent = ({ children, className = '' }: TNavLink) => {
      if (isOzonAuth && NAME === 'Профиль') {
        return null;
      } else
        return (
          <NavLink
            onClick={() => {
              setShowMenu(false);
              dispatch(burgerBlockActions.setOpenPopup(false));
            }}
            className={({ isActive }) =>
              cl(DEFAULT_LINK_CLASSNAMES, className, {
                [style.currentLink]: isActive,
              })
            }
            to={ROUTE}
          >
            {children}
          </NavLink>
        );
    };

    if (ANKER) {
      return (
        <a
          href={`#${ROUTE}`}
          onClick={evt => {
            evt.preventDefault();
            utils.scroolTo(`#${ROUTE}`);
            dispatch(burgerBlockActions.setOpenPopup(false));
          }}
          className={cl(DEFAULT_LINK_CLASSNAMES)}
          key={NAME}
        >
          {NAME}
        </a>
      );
    }
    if (ROUTE === ROUTES.CABINET_PROFILE.ROUTE) {
      return (
        <NavLinkComponent key={ROUTE}>
          <span className={style.nameWrap}>{NAME}</span>
        </NavLinkComponent>
      );
    }

    if (ROUTE === ROUTES.CABINET_NEW_TEST.ROUTE) {
      return (
        <NavLink
          to={ROUTE}
          onClick={() => {
            dispatch(burgerBlockActions.setOpenPopup(false));
          }}
          className={({ isActive }) =>
            cl(style.linkNewTest, btnStyle.button, btnStyle.withIcon, {
              [btnStyle.active]: isActive,
              [btnStyle.outline]: isActive,
              [btnStyle.disabled]: isActive,
            })
          }
          key={NAME}
        >
          <Icon name="icon-plus" />
          {NAME}
        </NavLink>
      );
    }
    if (ROUTE === ROUTES.CABINET_TESTS.ROUTE) {
      return (
        <span className={cl(style.linkSup)} key={NAME}>
          <NavLinkComponent>
            {NAME}
            <span className={style.supText}>{postCount}</span>
          </NavLinkComponent>
        </span>
      );
    }
    if (ROUTE === ROUTES.CABINET_BALANCE.ROUTE) {
      return (
        <span className={cl(style.linkSup, style.linkBalance)} key={NAME}>
          <NavLinkComponent>
            {NAME}

            {Price.isValid(clientBalance) ? (
              <span className={cl(style.supText, style.balance)}>
                {Price.getString(clientBalance)}
              </span>
            ) : null}
          </NavLinkComponent>
        </span>
      );
    }
    if (ROUTE === ROUTES.CABINET_HELP.ROUTE && isCabinet) {
      return (
        <button
          className={cl(style.link, style.linkMin, style.linkHelp)}
          onClick={onUserSupportOpen}
          key={ROUTE}
        >
          Поддержка
        </button>
      );
    }

    return <NavLinkComponent key={NAME}>{NAME}</NavLinkComponent>;
  };
  const checkNotMobileLink = ROUTE =>
    ROUTE === ROUTES.CABINET_NEW_TEST.ROUTE ||
    ROUTE === ROUTES.CABINET_TESTS.ROUTE;

  const onLogout = useCallback(() => {
    dispatch(authBlockActions.setCloseAuthPopup());
    dispatch(authBlockActions.setFormType(null));
    dispatch(burgerBlockActions.setOpenPopup(false));
    const originToken = localStorage.getItem('origin_auth_token');
    if (originToken) {
      localStorage.setItem(AUTH_TOKEN, originToken);
      localStorage.removeItem(ORIGIN_AUTH_TOKEN);
      document.location.reload();
    } else {
      dispatch(logoutUser());
    }
  }, [dispatch]);

  const { topService } = useIsIframe();

  const MenuContent = () => {
    return (
      <>
        {links
          .filter(({ ROUTE }) => !checkNotMobileLink(ROUTE))
          .map(renderLink)}
        {isCabinet && isOzonAuth && (
          <button
            className={cl(
              style.link,
              style.linkMin,
              style.linkHelp,
              style.linkOffer,
            )}
            onClick={onDownloadOffer}
            disabled={isDownloading}
          >
            Оферта
          </button>
        )}
        {isCabinet && topService === 'isTop' && !isOzonAuth && (
          <>
            <NavLink
              to={ROUTES.MAIN.ROUTE}
              className={cl(DEFAULT_LINK_CLASSNAMES, style.linkExit)}
              onClick={onLogout}
            >
              Выйти
            </NavLink>
          </>
        )}
      </>
    );
  };
  return (
    <>
      <nav
        className={cl(style.navbar, {
          [style.index]: isIndex,
          [style.cabinet]: isCabinet,
          [style.desktop]: isDesktop,
          [style.mobile]: isMobile,
        })}
      >
        {links.filter(({ ROUTE }) => checkNotMobileLink(ROUTE)).map(renderLink)}
        {!isIndex && (
          <>
            <button
              className={style.moreBtn}
              onClick={() => setShowMenu(!showMenu)}
              id={NavbarBtnsId.MoreBtn}
            >
              Ещё
              <Icon name="icon-options" />
            </button>
            {(burgerMenuOpen || showMenu) && (
              <Popup
                closeIds={[NavbarBtnsId.MoreBtn]}
                closeHandler={() => setShowMenu(false)}
                isShow={showMenu}
                className={style.popup}
                withCloseBtn={false}
                borderRadius={5}
                type={'navbar'}
              >
                <div className={style.mobileMenu}>
                  <MenuContent />
                </div>
              </Popup>
            )}
          </>
        )}

        {(!showMenu || isIndex) && (
          <div
            className={cl(style.mobileNav, {
              [style.show]: showMenu,
              [style.cabinet]: isCabinet,
            })}
          >
            <MenuContent />
          </div>
        )}
      </nav>
    </>
  );
};

export default Navbar;
